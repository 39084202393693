import * as React from 'react';
import { graphql } from 'gatsby';
import SiteWrapper from '../components/siteWrapper';
import Page from '../components/page';
import Nav from '../components/sponsoren/nav';

const IndexPage = (props) => {
    const node = props.data.allFile.edges[0].node.childMarkdownRemark;
    return (
        <SiteWrapper
            title="Förderverein - SV Kappel"
            description="Zur finanziellen Unterstützung der Abteilung Fußball haben wir den Verein Fairplay E.V. - Verein zur Förderung des Fußballs im SV Kappel gegründet."
        >
            <Nav />
            <Page headerImg={node.frontmatter.headerImg}>{node.html}</Page>
        </SiteWrapper>
    );
};

export default IndexPage;

export const query = graphql`
    query FVQuery {
        allFile(
            filter: {
                sourceInstanceName: { eq: "sites" }
                name: { eq: "foerderverein" }
            }
        ) {
            edges {
                node {
                    childMarkdownRemark {
                        html
                        frontmatter {
                            headerImg {
                                childImageSharp {
                                    gatsbyImageData
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
